import React from 'react';
import { Box, Typography } from '@material-ui/core';

import CustomInputNumber from 'commons/components/CustomInputNumber';
import colors from '../../../utils/colors';

const { primary } = colors;

const SalePrice = ({
  disabled = false,
  handleBlur = null,
  handleChange,
  isEditMode,
  priceToShow,
  value,
}) => {
  const price = priceToShow.split('$')[1];
  return isEditMode ? (
    <Box>
      <CustomInputNumber
        disabled={disabled}
        label="Precio de venta (CLP)"
        name="price"
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        error={false}
        helperText=""
      />
    </Box>
  ) : (
    <Typography
      variant="body1"
      color="primary"
      style={{ fontWeight: '700', fontSize: '2.125rem', fontFamily: 'Mark Pro' }}
    >
      <span itemProp="priceCurrency" content="CLP">$</span>
      <span itemProp="price">{price}</span>
    </Typography>
  );
};

export default SalePrice;
