import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  Grid,
  Step,
  Stepper,
  StepLabel,
  makeStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import { useAuth } from 'commons/context/user';
import {
  FIRST_STAGE_PURCHASE_ID,
  SECOND_STAGE_PURCHASE_ID,
  THIRD_STAGE_PURCHASE_ID,
  FOURTH_STAGE_PURCHASE_ID,
} from 'utils/consts';
import { useForm } from 'utils/hooks/useForm';
import saga from 'screens/PurchasePreview/saga';
import Contract from 'screens/TransferPreview/components/Contract/index.js';
import BikfyPay from 'screens/TransferPreview/components/BikfyPay/BikfyPay.js';
import SuccessPay from 'screens/TransferPreview/components/SuccessPay/SuccessPay.js';
import TransferSummary from 'screens/Transfer/components/transferPreview/components/transferSummary';
import DocumentModal from 'screens/TransferPreview/components/DocumentModal/index.js';
import PurchaseButton from './components/PurchaseButton/index.js';
import { purchasePreviewActions } from './reducer/index.js';
import styles from './styles.js';
import DeliveryMethodsBox from './components/DeliveryMethodsBox/index.js';

const useStyles = makeStyles(styles);

const CREDIT_CARD_PAYMENT = 'credit_card';
const CREDIT_CARD_PAYMENT_WITH_FEES = 'credit_card_with_fees';
const EXTERNAL_PAYMENT = 'external_payment';

const PurchasePreview = (props) => {
  const {
    app, objects, actions, formControls = {}, purchasePreview, coupons,
  } = props;
  // const { paymentMethod } = purchasePreview;
  const {
    getTransfer,
    deleteTransfer,
    createTransferPDF,
    setPaymentMethod,
    createPayment,
    createMercadoPagoPayment,
    confirmExternalPaymentToStore,
    nextStep,
  } = actions;
  const { id: transferId } = useParams();
  useEffect(() => {
    getTransfer({ transferId });
  }, []);
  // Empty dependency array ensures this only runs on mount
  // const transfer = objects?.transfers?.[transferId];
  const user = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const { stepper } = classes;
  const transfer = objects?.transfers?.[transferId];
  const communes = Object.values(props?.comunes).map((c) => ({ value: c?.id, label: c?.name }));

  // Convert documents to an array if it's an object, then filter it
  const documents = props?.objects?.documents && typeof props.objects.documents === 'object'
    ? Object.values(props.objects.documents).filter(
      (d) => (d.type === 'serial' || d.type === 'own_invoice') && d.bikeId === transfer?.bikeId,
    )
    : [];

  // Log to check the output
  // console.log(props.documents, props.objects.documents, documents, 'asdas');

  const sellerInfo = transfer?.sellerObject;
  // const transfer?.storeSeller = transfer?.storeSeller;
  // console.log('objectsOriginal OFFF:: ', Object.values(objects?.transfers)[0]?.buyerId);
  console.log('transfer OFFF:: ', transfer);
  // console.log('OBJECTS OFFF:: ', objects);
  // console.log('sellerInfo OFFF:: ', sellerInfo);

  const userType = user.id === Object.values(objects?.transfers)[0]?.buyerId // || user.id === transfer?.buyerId
    ? 'buyer'
    : user.id === Object.values(objects?.transfers)[0]?.sellerId // || user.id === transfer?.sellerId
      ? 'seller'
      : null;

  const buyerObject = Object.values(objects?.buyer)[0];
  const buyerInfo = {
    commune: buyerObject?.comuneId,
    addressStreet: buyerObject?.addressStreet,
    addressNumber: buyerObject?.addressNumber,
    apartment: buyerObject?.addressDetail,
  };

  const bikeDetails = transfer?.bike;
  const stepZ = transfer?.transferDetail?.step;
  const commission = Object.values(objects?.commissions)[0];

  const [selectedDeliveryMethod, setSelectedPaymentMethod] = useState('OnStore');
  const deliveryMethodSelect = (deliveryMethodKey) => {
    // Lógica para manejar el clic en el método de entrega
    console.log(`Se ha seleccionado el método de entrega: ${deliveryMethodKey}`);
    setSelectedPaymentMethod(deliveryMethodKey);
  };
  const [open, setOpen] = useState(false);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpen(true); // For local state management
  };

  // Function to handle closing the modal
  const handleClose = () => {
    setOpen(false);
  };

  // const step = 2;
  const [step, setStepEo] = useState(stepZ);
  const increaseStep = () => { setStepEo(step + 1); };
  const decreaseStep = () => { setStepEo(step - 1); };

  useEffect(() => {
    setStepEo(transfer?.transferDetail?.step);
    // setStepEo(4);
  }, [transfer?.transferDetail?.step]);

  useEffect(() => {
    const eo = props.actions.getStateFromApiComunes();
    console.log('EOzzz:: ', eo);
  }, []);
  console.log('ESTETESTETETE', transfer?.bike);

  useEffect(() => {
    if (transfer?.buyer?.taxNumber) {
      console.log('asdasd');
      actions.fetchCoupons({ taxNumber: transfer?.buyer?.taxNumber });
    }
  }, [transfer?.buyer?.taxNumber]);

  useEffect(() => {
    if (transfer?.buyer?.taxNumber) {
      console.log(coupons, 'logging here');
      // actions.fetchCoupons({ taxNumber: transfer?.buyer?.taxNumber });
    }
  }, [coupons]);

  const changeControls = useCallback((name, value) => {
    // if (name === 'email') {
    //   value = cleanWhiteSpaces(value);
    // }
    actions.changeControls({ [name]: value });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // onSubmit(() => actions.transferValidation({ id, ...formControls }));
  };

  const handleClickContinueButton = () => {
    increaseStep();
    nextStep({ transferId, formControls });
  };

  useEffect(() => {
    if (purchasePreview.transbank.url !== '' && purchasePreview.transbank.token !== '') {
      const urlOptions = {
        [EXTERNAL_PAYMENT]: purchasePreview.transbank.url.concat(`?token_ws=${purchasePreview.transbank.token}`),
        [CREDIT_CARD_PAYMENT]: purchasePreview.transbank.url.concat(`?token_ws=${purchasePreview.transbank.token}`),
        [CREDIT_CARD_PAYMENT_WITH_FEES]: purchasePreview.transbank.url,
      };
      // console.log(urlOptions[purchasePreview.paymentMethod]);

      window.location.replace(urlOptions[purchasePreview.paymentMethod]);
    }
  }, [purchasePreview.transbank]);

  useEffect(() => {
    if (selectedDeliveryMethod === 'OnStore') {
      actions.changeControls({ commune: transfer?.storeSeller?.comuneId || '' });
      actions.changeControls({ addressStreet: transfer?.storeSeller?.addressStreet || '' });
      actions.changeControls({ addressNumber: transfer?.storeSeller?.addressNumber || '' });
      actions.changeControls({ apartment: transfer?.storeSeller?.addressDetail || '' });
    } else {
      actions.changeControls({ commune: buyerInfo?.commune || '' });
      actions.changeControls({ addressStreet: buyerInfo?.addressStreet || '' });
      actions.changeControls({ addressNumber: buyerInfo?.addressNumber || '' });
      actions.changeControls({ apartment: buyerInfo?.apartment || '' });
    }
  }, [objects?.buyer, selectedDeliveryMethod]);

  const { onChange, onSubmit, errors } = useForm(formControls, null, {
    onChange: changeControls,
    validations: {
      commune: [
        {
          check: (value) => !!value,
          message: 'La comuna es requerida',
        },
      ],
      addressStreet: [
        {
          check: (value) => value && value.trim().length > 0,
          message: 'El nombre de calle es requerido',
        },
      ],
      addressNumber: [
        {
          check: (value) => value && value.trim().length > 0,
          message: 'El número de calle es requerido',
        },
      ],
      // apartment: [
      //   {
      //     check: (value) => value && value.trim().length > 0,
      //     message: 'El Precio de venta es requerido',
      //   },
      // ],
    },
  });

  const contractScreen = (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid container item xs={12} justifyContent="center" style={{ marginTop: '2rem' }}>
                <Typography
                  variant="h6"
                  component="span"
                  color="textSecondary"
                  style={{ fontWeight: '700', textTransform: 'uppercase' }}
                >
                  Revisa el
                  <Typography
                    variant="h6"
                    component="span"
                    color="primary"
                    style={{ fontWeight: '700', textTransform: 'uppercase' }}
                  >
                    {' '}
                    Contrato de Compraventa
                    {' '}
                  </Typography>
                  para una transferencia exitosa
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <Card variant="outlined" style={{ border: '1px solid black' }}>
                  <CardContent><Contract id={transferId} /></CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TransferSummary
                  handleOpenModal={handleOpenModal}
                  transfer={transfer}
                  amount={transfer?.amount}
                  buyer={transfer?.buyer}
                  seller={transfer?.seller}
                  bike={{ ...transfer?.bike, documents }}
                  storeSeller={transfer?.storeSeller}
                  purchase="purchase"
                />
                <DocumentModal
                  open={open}
                  clearModal={handleClose}
                  documents={documents}
                />
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <PurchaseButton
                  // id={id}
                  id={transferId}
                  // user={userType}
                  acceptSeller={props.actions.acceptTransferSeller}
                  acceptBuyer={props.actions.acceptTransferBuyer}
                  loading={props.loading}
                  alertDeny={props.alertDeny}
                  openAlertDeny={props.actions.openAlert}
                  clearAlertDeny={props.actions.clearAlert}
                  deleteTransfer={props.actions.deleteTransfer}
                  createTransferPDF={createTransferPDF}
                  increaseStep={increaseStep}
                  // decreaseStep={decreaseStep}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const deliveryScreen = (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid container item xs={12} justifyContent="center" style={{ marginTop: '2rem' }}>
                <Typography
                  variant="h6"
                  component="span"
                  color="textSecondary"
                  style={{ fontWeight: '700', textTransform: 'uppercase' }}
                >
                  ¿Cómo deseas
                  <Typography
                    variant="h6"
                    component="span"
                    color="primary"
                    style={{ fontWeight: '700', textTransform: 'uppercase' }}
                  >
                    {' '}
                    recibir tu bike
                    {' '}
                  </Typography>
                  ?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <DeliveryMethodsBox
                  actions={actions}
                  onControlChange={onChange}
                  onSubmit={handleSubmit}
                  handleClick={deliveryMethodSelect}
                  selectedDeliveryMethod={selectedDeliveryMethod}
                  controls={formControls}
                  communes={communes}
                  buyerInfo={buyerInfo}
                  sellerInfo={sellerInfo}
                  storeSellerInfo={transfer?.storeSeller}
                  transfer={transfer}
                />
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <Grid container justifyContent="center">
                  <Grid item xs={6} sm={3} md={2}>
                    <Button
                      onClick={decreaseStep}
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <Button
                      onClick={handleClickContinueButton}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Continuar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const paymentScreen = (
    <BikfyPay
      buyer={transfer?.buyer}
      transfer={transfer}
      commission={commission}
      createPayment={createPayment}
      createMercadoPagoPayment={createMercadoPagoPayment}
      setPaymentMethod={setPaymentMethod}
      paymentMethod={purchasePreview.paymentMethod}
      // loading={loading}
      // history={history}
      bikeInfo={transfer?.bike}
      storeSeller={transfer?.storeSeller}
      confirmExternalPaymentToStore={confirmExternalPaymentToStore}
      onControlChange={onChange}
      controls={formControls}
      decreaseStep={decreaseStep}
      isPurchase="purchase"
      coupons={coupons}
      // getCoupons={actions.getCoupons}
    />
  );

  const successPay = (
    <SuccessPay
      bikeDetails={bikeDetails}
      history={history}
      isBikfyPay={!transfer?.transferDetail.externalPayment}
      isPurchase="purchase"
    />
  );

  return (
    <Grid style={{ width: '100%' }}>
      <Stepper
        activeStep={step === FOURTH_STAGE_PURCHASE_ID ? step + 1 : step - 1}
        className={stepper}
      >
        <Step>
          <StepLabel />
        </Step>
        <Step>
          <StepLabel />
        </Step>
        <Step>
          <StepLabel />
        </Step>
        <Step>
          <StepLabel />
        </Step>
      </Stepper>

      <Grid>
        {step === FIRST_STAGE_PURCHASE_ID && (
          contractScreen
        )}
        {step === SECOND_STAGE_PURCHASE_ID && (
          deliveryScreen
        )}
        {step === THIRD_STAGE_PURCHASE_ID && (
          paymentScreen
        )}
        {step === FOURTH_STAGE_PURCHASE_ID && (
          successPay
        )}
      </Grid>
    </Grid>
  );
};

export default composedComponent(PurchasePreview, saga, {
  states: [
    'app.objects',
    'purchasePreview',
    'purchasePreview.formControls',
    'purchasePreview.coupons',
    // 'purchasePreview',
    'app.objects.comunes',
  ],
  actions: [appActions, purchasePreviewActions],
  saga: 'PurchasePreviewSaga',
});
