import {
  ButtonBase, Grid, Paper, Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import StorefrontIcon from '@material-ui/icons/Storefront';
import BeenhereIcon from '@material-ui/icons/Beenhere';

const FilterBikfyPublicationsButton = (props) => {
  const {
    classes, actions, filterForm, hideIconBreakpoint,
  } = props;

  const handleClick = () => {
    actions.setFilterForm({
      filterForm: {
        ...filterForm,
        searchBikfyPublications: !filterForm.searchBikfyPublications,
      },
    });
    actions.resetMarketplacePagination();
    actions.getPublishedBikes();
  };

  return (
    <ButtonBase onClick={handleClick} className={classes.searchBikfyPublicationsButtonBase}>
      <Grid
        container
        item
        xs={12}
        component={Paper}
        alignItems="center"
        justifyContent="center"
        className={
          filterForm.searchBikfyPublications
            ? classes.marketplacePublicationsGrid
            : classes.bikfyPublicationsGrid
        }
      >
        {' '}
        {!hideIconBreakpoint && (
          <Grid
            item
            style={{
              color: filterForm.searchBikfyPublications ? 'white' : '#FFD700',
              paddingTop: '5px',
              marginRight: '3px',
            }}
          >
            {filterForm.searchBikfyPublications ? <StorefrontIcon /> : <BeenhereIcon />}
          </Grid>
        )}
        <Grid item>
          <Typography align="center" className={classes.showBikfyPublicationsButtonText}>
            {filterForm.searchBikfyPublications
              ? 'VER BIKES MARKETPLACE'
              : 'VER SEMI-NUEVAS BIKFY'}
          </Typography>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default FilterBikfyPublicationsButton;
