// @ts-nocheck
import React from 'react';
// material
import logo from 'utils/images/logo-footer-200x72.png';
import logo2 from 'commons/assets/logo_horizontal0.png';
import Grid from '@material-ui/core/Grid';
import {
  Box,
  Container,
  Link,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import styles from './styles';

const useStyles = makeStyles(styles);
const infoUrl = process.env.REACT_APP_INFO_URL;

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const rrss = (
    <Grid item xs={12} sm={2}>
      <div>
        <img src={logo2} alt="logo" className={classes.logo} />
      </div>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Link href="https://www.facebook.com/profile.php?id=100063934805292">
            <FacebookIcon color="primary" fontSize="large" />
          </Link>
        </Grid>
        <Grid item>
          <Link href="https://www.instagram.com/bikfy.cl/">
            <InstagramIcon color="primary" fontSize="large" />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );

  const otherOptions = (
    <Grid item xs={12} sm={10}>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" component="h2">
            <Box fontWeight="fontWeightBold">Compañía</Box>
          </Typography>
          <Typography>
            <Link href={`${infoUrl}/sobre-nosotros/`} color="inherit">
              Sobre nosotros
            </Link>
          </Typography>
          {/* <Typography>
            <Link href={`${infoUrl}/equipo/`} color="inherit">
              Equipo
            </Link>
          </Typography> */}
          <Typography>
            <Link href={`${infoUrl}/colaboradores-alianzas/`} color="inherit">
              Colaboradores
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="h6" component="h2">
            <Box fontWeight="fontWeightBold">Media</Box>
          </Typography>
          <Typography>
            <Link href={`${infoUrl}/nutricion-y-salud-para-ciclistas-y-motociclistas/`} color="inherit">
              Blog BIKFY
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="h6" component="h2">
            <Box fontWeight="fontWeightBold">Recursos</Box>
          </Typography>
          <Typography>
            <Link href={`${infoUrl}/politica-de-privacidad2/`} color="inherit">
              Politica de privacidad
            </Link>
          </Typography>
          <Typography>
            <Link href={`${infoUrl}/terminos-y-condiciones2/`} color="inherit">
              Términos y condiciones
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="h6" component="h2">
            <Box fontWeight="fontWeightBold">Ayuda</Box>
          </Typography>
          <Typography>
            <Link
              href="https://api.whatsapp.com/send/?phone=56956365938&text&type=phone_number&app_absent=0"
              color="inherit"
            >
              Contacto y soporte
            </Link>
          </Typography>
          <Typography>
            <Link href={`${infoUrl}/faq/`} color="inherit">
              Preguntas frecuentes
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container className={classes.container} spacing={2}>
          {isXsScreen ? (
            <>
              {otherOptions}
              {rrss}
            </>
          ) : (
            <>
              {rrss}
              {otherOptions}
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
