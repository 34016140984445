import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  composedComponent, formatNumber, validateEmail, validateRut,
} from 'utils/functions';
import saga from 'screens/Store/saga';
import {
  Card, CardContent, Typography, withStyles,
} from '@material-ui/core';
import styles from 'commons/components/NavBar/styles';
import { appActions } from 'commons/reducer';
import Grid from '@material-ui/core/Grid';
import BikeForm from 'screens/Store/components/bikeForm';
import { Alert } from '@material-ui/lab';
import { useForm } from 'utils/hooks/useForm';
import { storeActions } from 'screens/Store/reducer';

const Store = (props) => {
  const [isBicycleSelected, setIsBicycleSelected] = useState(true);

  useEffect(() => {
    props.actions.fetchBrands();
    props.actions.fetchMotorbikeBrands();
    props.actions.fetchVehicleTypes();
  }, [
    props.actions.fetchBrands,
    props.actions.fetchMotorbikeBrands,
    props.actions.fetchVehicleTypes,
  ]);

  useEffect(() => {
    const vehicleTypeSelected = Object.values(props?.vehicleTypes)
      .filter((vehicleType) => vehicleType.id === props?.formControls?.vehicleTypeId)
      .pop();
    if (vehicleTypeSelected?.name && vehicleTypeSelected?.name !== 'Bicicleta') {
      setIsBicycleSelected(false);
    } else {
      setIsBicycleSelected(true);
    }
  }, [props.formControls.vehicleTypeId, props?.vehicleTypes]);

  const changeControls = useCallback((name, value) => {
    props.actions.changeControls({ [name]: value });
    if (name === 'vehicleTypeId') {
      props.actions.resetBikeControls();
    }
  }, []);

  const { onChange, onSubmit, errors } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {
      vehicleTypeId: [
        {
          check: (value) => !!value,
          message: 'El tipo de vehículo es requerido',
        },
      ],
      email: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'EL Correo electrónico es requerido',
        },
        {
          check: (value) => validateEmail(value),
          message: 'EL Correo electrónico es inválido',
        },
      ],
      taxNumber: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El R.U.T. es requerido',
        },
        {
          check: (value) => !!value && value.trim().length <= 15,
          message: 'El maximo es de 15 caracteres',
        },
        {
          check: (value) => validateRut(value),
          message: 'El R.U.T es inválido',
        },
      ],
      amount: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El valor es requerido',
        },
        {
          check: (value) => !!value && value.trim().length <= 150,
          message: 'El maximo es de 150 caracteres',
        },
        {
          check: (value) => !!value && /^\d+$/.test(value),
          message: 'Formato incorrecto',
        },
      ],
      invoiceNumber: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El número es requerido',
        },
        {
          check: (value) => !!value && value.trim().length <= 150,
          message: 'El maximo es de 150 caracteres',
        },
      ],
      serial: [
        {
          check: (value) => (!!value && value.trim().length > 0) || !isBicycleSelected,
          message: 'El C. de serie es requerido',
        },
        {
          check: (value) => (!!value && value.trim().length <= 150) || !isBicycleSelected,
          message: 'El maximo es de 150 caracteres',
        },
      ],
      chassisSerial: [
        {
          check: (value) => (!!value && value.trim().length > 0) || isBicycleSelected,
          message: 'El C. de serie es requerido',
        },
        {
          check: (value) => (!!value && value.trim().length <= 150) || isBicycleSelected,
          message: 'El maximo es de 150 caracteres',
        },
      ],
      engineSerial: [
        {
          check: (value) => (!!value && value.trim().length > 0) || isBicycleSelected,
          message: 'El C. de serie es requerido',
        },
        {
          check: (value) => (!!value && value.trim().length <= 150) || isBicycleSelected,
          message: 'El maximo es de 150 caracteres',
        },
      ],
      brandId: [
        {
          check: (value) => !!value,
          message: 'La Marca es requerida',
        },
      ],
      insurance: [
        {
          check: (value) => value !== null && value !== undefined && value !== '',
          message: 'El seguro seleccionado es requerido',
        },
      ],
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(() => props.actions.registerBike(props.formControls));
  };

  const brands = props.order?.brands
    ?.map((b) => props.brands[b])
    .map((a) => ({ value: a.id, label: a.name }));

  const motorbikeBrands = props.order?.motorbikeBrands
    ?.map((mb) => props.motorbikeBrands[mb])
    .map((a) => ({ value: a.id, label: a.name }));

  const vehicleTypes = props.order?.vehicleTypes
    ?.map((vt) => props.vehicleTypes[vt])
    .map((a) => ({ value: a.id, label: a.name }));

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={10} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" component="h5" align="center">
                  Registrar BIKE de cliente
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Alert severity="info">
                  <Typography variant="body2" color="textSecondary" component="p" align="center">
                    Recuerda que los datos del cliente deben ser los mismos ingresados en la factura
                    o boleta.
                  </Typography>
                </Alert>
              </Grid>
              <Grid item xs={12} sm={12}>
                <BikeForm
                  errors={errors}
                  brands={brands}
                  motorbikeBrands={motorbikeBrands}
                  vehicleTypes={vehicleTypes}
                  onControlChange={onChange}
                  controls={props.formControls}
                  onSubmit={handleSubmit}
                  loading={props.loading}
                  isBicycleSelected={isBicycleSelected}
                  uf={props.uf}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default composedComponent(Store, saga, {
  saga: 'sagaStore',
  middlewares: [withStyles(styles)],
  states: [
    'store.loading',
    'store.errors',
    'app.objects.brands',
    'app.objects.motorbikeBrands',
    'app.objects.vehicleTypes',
    'app.order',
    'store.formControls',
    'store.uf',
  ],
  actions: [storeActions, appActions],
});
