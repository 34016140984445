import React from 'react';
import PropTypes from 'prop-types';

import { AppBar } from '@material-ui/core';

const ResponsiveFilters = (props) => {
  const { isMobileScreen, isMarketPlace, children } = props;

  if (isMobileScreen && isMarketPlace) {
    return (
      <AppBar
        position="sticky"
        color="transparent"
        elevation={3}
        style={{ padding: '10px', marginBottom: '20px' }}>
        {children}
      </AppBar>
    );
  }

  return children;
};

export default ResponsiveFilters;
