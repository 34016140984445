import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  TextField,
  useMediaQuery,
  withStyles,
  useTheme,
  TableRow,
  TableCell,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useHistory, Link, useLocation } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import CustomTitleDefault from 'commons/components/CustomTitleDefault';
import { formatDateToString } from 'utils/functions';
import DisableDialog from 'screens/Admin/components/DisableDialog/DisableDialog';
import { userInfo } from 'utils/reportBikfy';
import BikfyReport from '../BikfyReport/BikfyReport';

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
  },
  title: {
    marginTop: 20,
  },
  wrapCarousel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 390,
  },
  imgCarousel: {
    maxWidth: '100%',
    maxHeight: 390,
  },
});

const SearchSummary = (props) => {
  const {
    params,
    searchBike,
    viewMaintenance,
    bike = {},
    buyer = {},
    user,
    styles,
    requestAccess,
    adminState,
    objects,
    actions,
    handleToggleDisableBike,
    handleOpenDisableBikeModal,
    openDisableBikeModal,
  } = props;

  const { buttonsContainer } = styles;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const location = useLocation();

  const isAdmin = user.type === 'admin';
  const isStore = user.type === 'store';

  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [message, setMessage] = useState('');

  const handleGoBack = () => {
    if (location?.state?.fromSearchView) {
      return history.push('/search');
    }
    return history.goBack();
  };

  const handleOpenWhatsApp = (phone) => {
    const whatsAppMessage = `Hola ${buyer.name}, nos ha interesado tu Bike para recibirla en parte de pago, necesitamos que agendes en el siguiente link una visita a taller para llevar tu bicicleta a revisión y poder dar cuenta del último precio oferta por tu nave!
Quedamos atentos a cualquier duda.`;
    const url = `https://wa.me/${phone}?text=${encodeURIComponent(whatsAppMessage)}`;
    window.open(url, '_blank');
  };

  const handleOpenEmailModal = () => {
    setOpenEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setOpenEmailModal(false);
    setMessage('');
  };

  const handleSendEmail = () => {
    // Implement email sending logic here
    handleCloseEmailModal();
  };

  const handleCloseDialog = () => {
    handleOpenDisableBikeModal(false);
  };

  const photos = (bike.documents || []).filter((file) => {
    if (['own_invoice', 'bikfy_sale'].includes(file.type)) {
      return isAdmin;
    }
    return true;
  });

  const handleToggleDisableBikeChild = ({ formValues, isDisable }) => {
    handleToggleDisableBike({ formValues, isDisable, bikeId: bike?.id });
  };

  useEffect(() => {
    searchBike(params);
  }, []);

  const Row = ({ name, value }) => (
    <TableRow key={name}>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell align="left">
        {name === 'Denuncia' ? (
          <strong style={{ color: value ? '#E64678' : 'green' }}>{value ? 'Si' : 'No'}</strong>
        ) : (
          value || 'No especificado'
        )}
      </TableCell>
    </TableRow>
  );
  // console.log(bike, 'asdasd', buyer);

  return (
    <>
      <Grid container xs={12} sm={12}>
        <Grid container xs={12}>
          <Grid item xs={6} sm={6}>
            <IconButton aria-label="go back" component="span" onClick={handleGoBack}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          {isAdmin && (
            <Grid container justifyContent="flex-end" xs={6} sm={6}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                onClick={() => handleOpenDisableBikeModal(true)}
              >
                {bike.disabled === 'No' ? 'INHABILITAR' : 'HABILITAR'}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <CustomTitleDefault title="Informe BIKFY" />
      {(isAdmin) && (
        <>
          <Typography variant="h6" color="textSecondary">
            Información del usuario
          </Typography>

          <Card>
            <CardContent>
              <Grid container spacing={3}>
                {userInfo.map(
                  (info) => info.shows.includes(user.type) && (
                  <Grid item xs={12} sm={6} key={info.key}>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={2} sm={1}>
                        {info.icon}
                      </Grid>
                      <Grid item xs>
                        <Typography color="textSecondary">{info.label}</Typography>
                        <Typography variant="body1">
                          {info.key === 'birthdate'
                            ? formatDateToString(buyer[info.key])
                            : buyer[info.key]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  ),
                )}
              </Grid>
            </CardContent>
          </Card>
        </>
      )}

      {/* Email Modal */}
      <Dialog open={openEmailModal} onClose={handleCloseEmailModal}>
        <DialogTitle>¡Envía un correo al vendedor para contactarlo!</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField label="Nombre" value={user.firstName || 'Admin'} fullWidth disabled />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Apellido" value={user.lastName || 'Admin'} fullWidth disabled />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Correo" value={user.email || 'admin@example.com'} fullWidth disabled />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Teléfono" value={user.phone || '+56988777654'} fullWidth disabled />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Mensaje"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEmailModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSendEmail} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>

      {photos.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Carousel interval={10000}>
              {photos.map((item) => {
                const { file } = item;
                const isPDF = file.name.split('.').pop().toLowerCase() === 'pdf';

                return (
                  <div key={item.id} className={classes.wrapCarousel}>
                    {!isPDF ? (
                      <img src={file.url} alt={file.name} className={classes.imgCarousel} />
                    ) : (
                      <iframe
                        title={file.name}
                        src={`${file.url}#toolbar=0&navpanes=0&scrollbar=0`}
                        height="390"
                        width="300"
                        frameBorder="0"
                      />
                    )}
                    <IconButton
                      aria-label="download"
                      component="span"
                      onClick={() => window.open(file.url, '_blank')}
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  </div>
                );
              })}
            </Carousel>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>
          <BikfyReport bike={bike} userType={user.type} />
        </CardContent>
      </Card>
      <Grid container spacing={3} alignItems="center" style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12}>
          <Grid container className={`${buttonsContainer}`} spacing={2}>
            <Grid item xs={12} sm={1} className="button-container">
              <IconButton aria-label="go back" component="span" onClick={handleGoBack}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={3} className="button-container">
              {bike?.saleDocument && (
              <Link to={`/transfer/${bike.saleDocument}/contract`}>
                <Button color="primary" fullWidth>
                  Ver contrato de compra/venta
                </Button>
              </Link>
              )}
            </Grid>

            {/* WhatsApp and Correo buttons for admin if bike is in evaluation */}
            {isAdmin && bike.inEvaluation && (
            <>
              <Grid item xs={12} sm={2} className="button-container">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenWhatsApp(buyer.phone)}
                  fullWidth
                >
                  WhatsApp
                </Button>
              </Grid>
              <Grid item xs={12} sm={2} className="button-container">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenEmailModal}
                  fullWidth
                >
                  Correo
                </Button>
              </Grid>
            </>
            )}

            <Grid item xs={12} sm={2} className="button-container">
              <Button
                type="submit"
                variant="contained"
                onClick={viewMaintenance}
                fullWidth
                style={{
                  color: '#fff',
                  backgroundColor: '#303e48',
                }}
              >
                Libro Taller
              </Button>
            </Grid>

            {isAdmin && (
            <Grid item xs={12} sm={2} className="button-container">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                onClick={() => handleOpenDisableBikeModal(true)}
                fullWidth
              >
                {bike.disabled === 'No' ? 'INHABILITAR' : 'HABILITAR'}
              </Button>
            </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <DisableDialog
        open={openDisableBikeModal}
        handleToggleShowDisableDialog={handleCloseDialog}
        disabledReasons={objects.disabledReasons}
        disableFormDialog={adminState.disableFormDialog}
        handleToggleDisableBike={handleToggleDisableBikeChild}
        isDisable={!(bike.disabled === 'No')}
        actions={actions}
        disabledReasonIds={objects?.bikes[adminState.disableDialog.bikeId]?.disabledBikes?.map(
          (disabledBike) => disabledBike.disabledReasonId,
        )}
      />
    </>
  );
};

export default SearchSummary;
