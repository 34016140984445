import React from 'react';
import {
  Grid, Typography, Button, makeStyles, Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ButtonBike from 'commons/assets/banner/istotipo_enduro_blanco.png';
import ButtonMotorbike from 'commons/assets/banner/MX_blanco_cropped.png';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import { PATHS } from 'utils/paths';
import gif from '../../../../commons/assets/landing_items/tutorial_gif.GIF'; // Adjust the path as needed

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(6),
    backgroundColor: '#f0f8ff',
  },
  phoneGifContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneGif: {
    height: '100%',
    objectFit: 'contain',
    display: 'block',
    maxHeight: '500px',
  },
  listContainer: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  listItem: {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(2.5),
    color: '#303E48',
  },
  button: {
    marginTop: theme.spacing(2),
    height: '50px',
    maxWidth: '350px',
    borderRadius: '30px',
    alignSelf: 'center',
  },
  title: {
    fontWeight: '500',
    fontSize: '30px',
    paddingBottom: '12px',
    color: '#303E48',
  },
  bodyText: {
    color: '#303E48',
    marginBottom: theme.spacing(3),
  },
  bannerRegisterButton: {
    width: '49px',
    height: '30px',
    marginRight: '5px',
    marginLeft: '10px',
    [theme.breakpoints.between(0, 600)]: {
      width: '44px',
      height: '27px',
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  bannerRegisterButtonMotorbike: {
    width: '54px',
    height: '35px',
    marginRight: '10px',
    marginLeft: '5px',
    [theme.breakpoints.between(0, 600)]: {
      width: '44px',
      height: '30px',
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  centeredText: {
    textAlign: 'center', // Center text for mobile view
  },
}));

const PhoneWithList = ({ user, isMobileScreen }) => {
  const history = useHistory();
  const classes = useStyles();

  const handleRedirectToRegisterBike = () => {
    if (user?.user?.type === 'ambassador') {
      history.push(PATHS.AMBASSADOR);
      return;
    }
    if (user?.user?.type === 'store') {
      history.push(PATHS.STORE_BIKE_REGISTER);
      return;
    }
    history.push(PATHS.BIKE_REGISTER);
  };

  return (
    <div className={classes.container}>
      <Typography
        align="center"
        variant="h5"
        component="h5"
        gutterBottom
        className={`${classes.title} ${isMobileScreen ? classes.centeredText : ''}`}
      >
        ¿Cómo funciona?
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className={classes.phoneGifContainer}>
          <img
            src={gif}
            alt="Phone GIF"
            className={classes.phoneGif}
          />
        </Grid>
        <Grid item xs={12} md={7} className={`${classes.listContainer} ${isMobileScreen ? classes.centeredText : ''}`}>
          <div>
            <Typography variant="h6" component="h3" gutterBottom className={`${classes.bodyText} ${isMobileScreen ? classes.centeredText : ''}`}>
              ¡Todo comienza con el Registro GRATIS de tu Bike!
            </Typography>
            <Typography variant="body1" component="p" gutterBottom className={`${classes.bodyText} ${isMobileScreen ? classes.centeredText : ''}`}>
              Al hacerlo accederás a nuestro ecosistema de servicios, funciones y beneficios.
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography variant="body1" component="p" gutterBottom className={`${classes.bodyText} ${isMobileScreen ? classes.centeredText : ''}`}>
                  Registrar la propiedad de tu Bike.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography variant="body1" component="p" gutterBottom className={`${classes.bodyText} ${isMobileScreen ? classes.centeredText : ''}`}>
                  Publicar en el Marketplace para vender seguro.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography variant="body1" component="p" gutterBottom className={`${classes.bodyText} ${isMobileScreen ? classes.centeredText : ''}`}>
                  Transferir legalmente la propiedad a tu comprador.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography variant="body1" component="p" gutterBottom className={`${classes.bodyText} ${isMobileScreen ? classes.centeredText : ''}`}>
                  Ofrecer facilidades de pago a tu comprador con BIKFYPAY.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography variant="body1" component="p" gutterBottom className={`${classes.bodyText} ${isMobileScreen ? classes.centeredText : ''}`}>
                  Contratar Seguros de robo y pérdida total.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography variant="body1" component="p" gutterBottom className={`${classes.bodyText} ${isMobileScreen ? classes.centeredText : ''}`}>
                  Gozar de los miles de beneficios del Club BIKFY.
                </Typography>
              </li>
            </ul>
          </div>
          <Button
            id="register-bike-button"
            color="primary"
            variant="contained"
            onClick={handleRedirectToRegisterBike}
            style={{
              height: '60px',
              maxWidth: '350px',
              borderRadius: '30px',
            }}
            startIcon={(
              <img
                src={ButtonBike}
                alt="Custom Image"
                className={classes.bannerRegisterButton}
              />
            )}
            endIcon={(
              <img
                src={ButtonMotorbike}
                alt="Custom Image"
                className={classes.bannerRegisterButtonMotorbike}
              />
            )}
          >
            <Typography className={classes.bannerRegisterButtonText} align="center">
              Registra tu bike
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PhoneWithList;
