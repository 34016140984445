/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';

import xlsx from 'xlsx';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { CREDIT_CARD_PAYMENT, CREDIT_CARD_PAYMENT_WITH_FEES } from 'utils/consts';
import logoMercadoPago from 'commons/assets/paymentMethods/logo_mercado_pago.png';
import logoTransBank from 'commons/assets/paymentMethods/logo_transbank.png';
import { composedComponent, formatNumberToCLP } from '../../utils/functions';
import adminPaySaga from './adminPay.saga';
import adminPayActions from './adminPay.actions';

import styles from './styles';

const AdminPay = (props) => {
  const { history, adminPay: adminPayState, actions, classes } = props;

  const { csvData, payedTransfers } = adminPayState;

  const {
    uploadExcel,
    getPendingTransfer,
    clearCsvPayedTransfer,
    sendTransferEmailsToPayToSellers,
  } = actions;

  const { generalContainer, titleContainer, buttonsContainer, tableContainer } = classes;

  const { state } = useParams();

  const isUploadExcel = state === 'import';

  const uploadButtonRef = useRef(null);

  const handleClickUploadButton = () => {
    uploadButtonRef.current.click();
  };

  const handleChangeButtonFile = (event) => {
    const { files } = event.target;
    const file = files[0];
    uploadExcel({ file });
  };

  const exportTransfersExcel = () => {
    const mappedCsvData = csvData.map((data) => {
      const { buyerPayMethod, transferId, ...rest } = data;

      return rest;
    });

    const worksheet = xlsx.utils.json_to_sheet(mappedCsvData, { skipHeader: true });
    const csv = xlsx.utils.sheet_to_csv(worksheet, {
      FS: ';',
    });
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    const file = window.URL.createObjectURL(blob);
    window.location.assign(file);
  };

  const handleClickExcelExport = () => {
    exportTransfersExcel();

    const transferIds = csvData.map((data) => data.transferId);
    sendTransferEmailsToPayToSellers({ transferIds });
  };

  useEffect(() => {
    if (!isUploadExcel) {
      getPendingTransfer();
    } else {
      clearCsvPayedTransfer();
    }
  }, [isUploadExcel]);

  const paymentMethod = (buyerPayMethod) => {
    const options = {
      [CREDIT_CARD_PAYMENT]: logoTransBank,
      [CREDIT_CARD_PAYMENT_WITH_FEES]: logoMercadoPago,
    };

    const logo = options[buyerPayMethod] || null;

    return logo ? (
      <img src={logo} alt="Logo Método Pago" style={{ width: '60px' }} />
    ) : (
      'Desconocido'
    );

    return;
  };

  return (
    <Grid component={Paper} className={`${generalContainer}`}>
      <Box className={`${titleContainer}`}>
        <Typography className="title">
          {isUploadExcel ? 'Pagos ' : 'Pagos a '}
          <span className="bikfy-color">{isUploadExcel ? 'realizados' : 'realizar'}</span>
        </Typography>
        <Alert severity="info">
          {isUploadExcel
            ? 'Ingresa el excel para poder cargar la información'
            : `Tienes ${csvData.length} pagos pendientes`}
        </Alert>
      </Box>

      <Box className={`${buttonsContainer}`}>
        {isUploadExcel ? (
          <Box>
            {/* TODO: componente */}
            <input
              accept="image/*"
              type="file"
              ref={uploadButtonRef}
              style={{ display: 'none' }}
              onChange={handleChangeButtonFile}
            />
            <Button variant="contained" color="primary" onClick={handleClickUploadButton}>
              Subir excel
            </Button>
          </Box>
        ) : (
          <Button variant="contained" color="primary" onClick={handleClickExcelExport}>
            Exportar excel
          </Button>
        )}
      </Box>

      <TableContainer className={`${tableContainer}`}>
        {isUploadExcel ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell>Rut</TableCell>
                <TableCell>Transfer ID</TableCell>
                <TableCell>Monto</TableCell>
                <TableCell>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payedTransfers.map(({ name, rut, invoice, bikfyPaidStatus, amount }) => (
                <TableRow key={invoice}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{rut}</TableCell>
                  <TableCell>{invoice}</TableCell>
                  <TableCell>{formatNumberToCLP(amount)}</TableCell>
                  <TableCell>{bikfyPaidStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Rut</TableCell>
                <TableCell>Transfer ID</TableCell>
                <TableCell>Monto</TableCell>
                <TableCell>Método Pago</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {csvData.map(
                ({ titularName, titularRut, invoice, amount, buyerPayMethod, userType }) => (
                  <TableRow key={invoice}>
                    <TableCell>{titularName}</TableCell>
                    <TableCell>{userType}</TableCell>
                    <TableCell>{titularRut}</TableCell>
                    <TableCell>{invoice}</TableCell>
                    <TableCell>{formatNumberToCLP(amount)}</TableCell>
                    <TableCell>{paymentMethod(buyerPayMethod)}</TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {/*
      <Alert severity="warning" style={{ marginTop: '20px' }}>
        <Typography>
          {isUploadExcel
            ? 'Sube el excel para ver la información'
            : 'Exporta el excel para mostrar la información'}
        </Typography>
      </Alert> */}
    </Grid>
  );
};

AdminPay.propTypes = {};

AdminPay.defaultProps = {};

export default composedComponent(AdminPay, adminPaySaga, {
  saga: 'adminPaySaga',
  middlewares: [withStyles(styles)],
  states: ['adminPay'],
  actions: [adminPayActions],
});
