import { takeLatest, spawn, put } from 'redux-saga/effects';
import { apiSuccess, post, get, postForm } from 'utils/api';
import * as APP from 'commons/reducer';

import { PROFILE } from 'screens/Profile/reducer';
import { STORE } from 'screens/Store/reducer';

function* fetchBrands() {
  yield takeLatest(STORE.FETCH_BRANDS, function* (action) {
    const response = yield get('api/v1/brands');
    if (!response.error) {
      yield put(apiSuccess(STORE.FETCH_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar marcas' }));
    }
  });
}

function* fetchMotorbikeBrands() {
  yield takeLatest(STORE.FETCH_MOTORBIKE_BRANDS, function* (action) {
    const response = yield get('api/v1/motorbike-brands');
    if (!response.error) {
      yield put(apiSuccess(STORE.FETCH_MOTORBIKE_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar marcas' }));
    }
  });
}

function* fetchVehicleTypes() {
  yield takeLatest(STORE.FETCH_VEHICLE_TYPES, function* (action) {
    const response = yield get('api/v1/vehicle-types');
    if (!response.error) {
      yield put(apiSuccess(STORE.FETCH_VEHICLE_TYPES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar marcas' }));
    }
  });
}

function* registerBike() {
  yield takeLatest(STORE.REGISTER_BIKE, function* (action) {
    const body = new FormData();

    for (const key of Object.keys(action.payload)) {
      let value = action.payload[key];
      if (typeof value === 'string') {
        value = value.trim();
      }
      body.append(`${key}`, value);
    }
    const response = yield postForm('api/v1/bikes/register-bike-store', body);

    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, 'Registro exitoso'));
      yield put(apiSuccess(STORE.REGISTER_BIKE_SUCCESS));
      yield put(apiSuccess(STORE.RESET_CONTROLS));
    } else {
      yield put(
        apiSuccess(APP.SET_ERROR, {
          errorMsg: response?.errorMsg || 'El código de serie ya se encuentra registrado',
        }),
      );
      yield put(apiSuccess(STORE.REGISTER_BIKE_ERROR));
    }
  });
}

function* updateBrands() {
  yield takeLatest(APP.GET_STATE_FROM_API, function* (action) {
    const response = yield post('api/v1/');
    if (!response.error) {
      // const comunes = response.reduce((ac, i) => ({...ac, [i.id]:i}), {});
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    }
  });
}

function* getStateFromApiComunes() {
  yield takeLatest(PROFILE.GET_STATE_FROM_API_COMUNES, function* (action) {
    const response = yield get('api/v1/comunes/');
    yield put(apiSuccess(PROFILE.GET_STATE_FROM_API_COMUNES_SUCCESS, response));
  });
}

export default function* root() {
  yield spawn(fetchBrands);
  yield spawn(registerBike);
  yield spawn(fetchMotorbikeBrands);
  yield spawn(fetchVehicleTypes);
  // yield spawn(loadState);
}
