import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import imageNotBike from '../../assets/not-bike.svg';

const NotBike = (props) => {
  const {
    filter, loading, isMarketPlace, isStoreHome,
  } = props;

  const history = useHistory();

  const handleClick = () => {
    history.push('/register-bike');
  };

  if (loading) {
    return null;
  }
  const textInfo = (filter) => {
    switch (filter) {
      case 1:
        return 'Actualmente no tienes Bikes';
      case 2:
        return 'Actualmente no tienes Bikes Transferidas';
      case 3:
        return 'Actualmente no tienes Bikes Pendientes';
      case 4:
        return '😪 Lo sentimos, de momento no tienes Bicicletas que cumplan con los requisitos para ser aseguradas (antigüedad máxima de 6 meses desde su compra original, precio menor o igual a $5.000.000 y contar con la boleta o factura de compra). Esperamos que prontamente podamos flexibilizar estos requisitos y abarcar todas las bicicletas.';
      default:
        return 'Actualmente no tienes Bikes';
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              spacing={3}
            >
              <Grid item xs={12} align="center">
                <img style={{ width: '250px' }} src={imageNotBike} alt="" />
              </Grid>
              <Grid item xs={12}>
                {isMarketPlace ? (
                  <Typography component="h5" variant="h5" align="center">
                    No existen Bikes publicadas con los parámetros indicados
                  </Typography>
                ) : (
                  <Typography component="h5" variant="h5" align="center">
                    {textInfo(filter)}
                  </Typography>
                )}
              </Grid>
              {!isMarketPlace && !isStoreHome && (
                <>
                  {filter === 1 && (
                    <Grid item xs>
                      <Button variant="contained" color="primary" onClick={handleClick}>
                        Registrar
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NotBike;
