/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CustomTitleDefault from 'commons/components/CustomTitleDefault';
import BenefitsTable from 'commons/components/BenefitsTable';
// Project
import saga from 'screens/Benefit/saga';

import {
  Container, Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import { benefitActions } from 'screens/Benefit/reducer';
import AlertModal from 'screens/Benefit/components/AlertModal';
import ProvidersList from 'commons/components/ProvidersList';
import { useLocation, useHistory } from 'react-router-dom';

const Benefit = (props) => {
  const {
    alert,
    completedFetchBikes,
    hasValidBikes,
    actions,
    loading,
    benefits,
    providers,
    user,
    oneclickSubscriptionUrl,
    loadingSubscriptions,
    loadingProviders,
    errorSubscriptions,
    loadingConfirmation,
    errorProviders,
    subscriptions,
  } = props;
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    actions.fetchBikes();
    actions.fetchProviders();
    actions.fetchSubscriptions();
  }, []);

  const getQueryParams = (query) => new URLSearchParams(query);

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const TBK_TOKEN = queryParams.get('TBK_TOKEN');
    const subscriptionId = queryParams.get('subscriptionId');

    console.log('Query Params:', TBK_TOKEN, subscriptionId);

    if (TBK_TOKEN && subscriptionId) {
      actions.confirmSubscription({ token: TBK_TOKEN, subscriptionId });
      history.push('/club-bikfy');
    }
  }, [location]); // React to changes in location

  useEffect(() => {
    if (loading && completedFetchBikes) {
      if (hasValidBikes) {
        actions.fetchBenefits();
      } else {
        actions.openAlert();
      }
    }
  }, [completedFetchBikes, hasValidBikes, loading]);

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10}>
          <Card>
            <CardContent>
              <CustomTitleDefault
                title="Club BIKFY"
              />

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <ProvidersList
                    data={Object.values(providers || {})}
                    loadingProviders={loadingProviders}
                    loadingSubscriptions={loadingSubscriptions}
                    loadingConfirmation={loadingConfirmation}
                    // errorSubscription={errorSubscriptions}
                    errorProviders={errorProviders}
                    createSubscription={actions.createSubscription}
                    userId={user.id}
                    oneclickSubscriptionUrl={oneclickSubscriptionUrl}
                    subscriptions={subscriptions || {}}
                    isMobileScreen={isMobileScreen}
                    hasValidBikes={hasValidBikes}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" color="textSecondary">
                    Descuentos
                  </Typography>
                  <BenefitsTable
                    data={Object.values(benefits || {})}
                    loading={loading}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body2" color="textSecondary" component="p" align="right">
                    * IB y CI: Presentando Informe BIKFY y Cédula de Identidad
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" align="right">
                    ** Debes adjuntar una foto del Informe BIKFY
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <AlertModal
        alert={alert}
        clearAlert={actions.clearAlert}
      />
    </Container>
  );
};

export default composedComponent(Benefit, saga,
  {
    saga: 'sagaBenefit',
    states:
      [
        'benefit.alert',
        'benefit.loading',
        'benefit.completedFetchBikes',
        'benefit.error',
        'benefit.message',
        'benefit.hasValidBikes',
        'benefit.oneclickSubscriptionUrl',
        'benefit.loadingSubscriptions',
        'benefit.loadingConfirmation',
        'benefit.errorSubscriptions',
        'benefit.loadingProviders',
        'benefit.errorProviders',
        'app.objects.benefits',
        'app.objects.providers',
        'app.objects.subscriptions',
        'app.user.user',
        // 'app.subUrl',

      ],
    actions: [benefitActions, appActions],
  });
