import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { appActions } from 'commons/reducer';
import { composedComponent } from 'utils/functions';
// import { post } from 'utils/api';
import { apiRequest } from 'utils/api';
import saga from 'screens/Home/saga';
import { styles } from './styles';
import { homeActions } from '../../reducer';
import image1 from '../../../../commons/assets/ad1.png';
import image2 from '../../../../commons/assets/ad2.png';
import image3 from '../../../../commons/assets/ad3.png';

const AdCard = (props) => {
  const {
    classes,
    listType,
    handleClickCard,
    fullImage,
    imageUrl,
    targetUrl,
    fetchAds,
    actions,
  } = props;
  const [adData, setAdData] = useState(2);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const isMozaicList = listType === 'mozaic-list';
  const titleCardClass = isMozaicList ? '' : classes.normalListTitleCard;

  const type = isMozaicList ? 'only_image' : 'list_only_image';

  useEffect(() => {
    setLoading(true);
    setAdData(2);
    actions.fetchAds({
      type,
      callback: (err, data) => {
        if (err) {
          setError(err);
        } else {
          setAdData(data[0]);
        }
      },
    });
  }, [actions, listType]);

  useEffect(() => {
    if (adData?.imageUrl) {
      const img = new Image();
      img.src = adData.imageUrl;
      img.onload = () => {
        setLoading(false);
      };
      img.onerror = () => {
        setError('Image failed to load');
        setLoading(false);
      };
    }
  }, [adData]);

  const handleClickImage = (event) => {
    event.stopPropagation();
    actions.clickAd({ adId: adData?.id, link: adData?.link });
    window.open(adData?.link, '_blank');
    // window.location.href = adData?.link;
  };
  const size = !isMozaicList ? '920x248.8' : '312x493';
  const imageR = !isMozaicList ? image3 : (Math.random() < 0.5 ? image1 : image2);

  return (
    <Card
      className={`${classes.root} ${isMozaicList ? classes.minHeight430px : ''}`}
      style={isMozaicList ? { flexDirection: 'column', cursor: 'pointer', width: '100%' } : { cursor: 'pointer' }}
      onClick={handleClickCard}
    >
      <Grid container>
        <Grid item xs={12} style={{ position: 'relative' }}>
          <img
            src={adData?.imageUrl || `https://via.placeholder.com/${size}`}
            alt="Ad"
            loading="lazy" // Add lazy loading
            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
            onClick={handleClickImage}
          />
          {loading && (
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
        {!fullImage && (
          <>
            <Grid item sm={isMozaicList ? 12 : 5} xs={12}>
              <div style={{ width: '100%', height: isMozaicList ? '199px' : '251px', backgroundColor: '#ddd' }}>
                <img src={adData?.imageUrl || 'https://via.placeholder.com/600x200'} alt="Ad" style={{ width: '100%', height: '100%' }} />
              </div>
            </Grid>
            <Grid item sm={isMozaicList ? 12 : 7} xs={12} className={classes.details}>
              <CardContent className={classes.content}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Box className={classes.bikfyGreyColor} fontWeight="fontWeightBold">
                      Patrocinado
                    </Box>
                  </Grid>
                </Grid>
                <Grid item className="ad-info-container">
                  <Typography
                    itemProp="name"
                    variant="h2"
                    style={{
                      fontWeight: '500',
                      fontSize: '1.25rem',
                      fontFamily: 'Mark Pro',
                      lineHeight: 1.6,
                    }}
                  >
                    <Box className={`${classes.bikfyGreyColor} ${titleCardClass}`}>
                      Promueve tu producto o servicio.
                    </Box>
                  </Typography>
                </Grid>
                <Box display="flex" justifyContent="space-between" marginTop={2}>
                  <Button variant="contained" color="primary" onClick={handleClickImage}>
                    Saber más
                  </Button>
                </Box>
              </CardContent>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default composedComponent(AdCard, saga, {
  saga: 'sagaHome',
  middlewares: [withStyles(styles)],
  states: [
    'app.objects',
    'app.objects.transfers',
    'app.order',
    'app.user',
    'home',
    'home.pathType',
    'home.sortBy',
    'home.storeHomeSortBy',
    'home.ads',
  ],
  actions: [homeActions, appActions],
});
