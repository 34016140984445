import { takeLatest, spawn, put } from 'redux-saga/effects';

import apiRequest, { apiSuccess, post } from 'utils/api';
import * as APP from 'commons/reducer';
import { LOGIN } from '../reducer';

function* login() {
  yield takeLatest(LOGIN.LOGIN_USER, function* (action) {
    const body = JSON.stringify(action.controls);
    const response = yield apiRequest('login', { method: 'post', body });

    if (!response.error) {
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
      yield put(apiSuccess(LOGIN.LOGIN_USER_SUCCESS, response));
    } else {
      yield put(apiSuccess(LOGIN.LOGIN_USER_FAIL, response));
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Falló en el inicio de sesión' }));
    }
  });
}

function* verify() {
  yield takeLatest(LOGIN.VERIFY, function* (action) {
    const body = JSON.stringify({ token: action.token });
    const response = yield apiRequest('api/v1/users/verify', { method: 'post', body });
    if (!response.error) {
      yield put(apiSuccess(LOGIN.VERIFY_SUCCESS, response));
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
    } else {
      yield put(apiSuccess(LOGIN.VERIFY_FAIL, response));
    }
  });
}

function* resendEmailActivation() {
  yield takeLatest(LOGIN.RESEND_EMAIL_ACTIVATION, function* (action) {
    const body = { email: action.email };
    const response = yield post('api/v1/register/activation', body);
    if (!response.error) {
      yield put(apiSuccess(LOGIN.RESEND_EMAIL_ACTIVATION_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, response.message));
    } else {
      yield put(apiSuccess(LOGIN.RESEND_EMAIL_ACTIVATION_FAIL));
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: response.errorMsg }));
    }
  });
}

export default function* root() {
  yield spawn(login);
  yield spawn(verify);
  yield spawn(resendEmailActivation);
}
